import { inject } from '@angular/core';
import { Router } from '@angular/router';

export function canRegisterLoan() {
  const router = inject(Router);
  const currentUser = JSON.parse(localStorage.getItem('currentUser') || '{}');
  if (currentUser.userPermissions?.canRegisterLoan) {
    return currentUser.userPermissions?.canRegisterLoan;
  } else {
    router.navigate(['/']);
    return false;
  }
}
