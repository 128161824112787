import { inject } from '@angular/core';
import { Router } from '@angular/router';

export function authGuard() {
  const router = inject(Router);
  const user = !!localStorage.getItem('authConfig');
  if (!user) {
    router.navigate(['/tenants']);
  }
  return user;
}

export function publicGuard() {
  const user = !localStorage.getItem('authConfig');
  return user;
}
