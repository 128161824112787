import { inject } from '@angular/core';
import { Router } from '@angular/router';

export function canNavigateManagementPage() {
  const router = inject(Router);
  const currentUser = JSON.parse(localStorage.getItem('currentUser') || '{}')

  if(currentUser.userPermissions?.canConfigure) {
    return currentUser.userPermissions?.canConfigure;
  } else {
    router.navigate(['/']);
    return false;
  }

}
