import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

export function PBGuard() {
  const router = inject(Router);

  if (environment.enablePB) {
    return true;
  }
  router.navigate(['/']);
  return environment.enablePB;
}
